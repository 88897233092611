import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import bnr1 from "./../../images/line2.png";
import team1 from "./../../images/team/img1.jpg";
// import team2 from './../../images/team/pic2.jpg';
// import team3 from './../../images/team/pic3.jpg';
// import team4 from './../../images/team/pic4.jpg';
// import team5 from './../../images/team/pic5.jpg';
// import team6 from './../../images/team/pic6.jpg';
// import team7 from './../../images/team/pic7.jpg';
// import team8 from './../../images/team/pic8.jpg';

const teamBlog = [{ image: team1 }];

class Teachers extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Teachers" activeMenu="Teachers" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    About the Teachers
                  </h2>
                  <p>
                    We have an excellent teacher to child ratio at our
                    pre-school to ensure that each child receives the attention
                    he or she needs
                  </p>
                </div>
                <div>
                  <img src={team1} alt="" />
                </div>
                <div className="section-head mt-4">
                  <p>
                    At Kites Prepschool, we are proud to have a team of
                    passionate and dedicated staff members who are committed to
                    providing a child-centric curriculum and an abstract
                    approach to education. Our staff members believe that every
                    child is unique and has their own learning style, which is
                    why we provide personalized attention and individualized
                    support to each student.
                  </p>
                  <p>
                    Our teachers are trained professionals with years of
                    experience in early childhood education. They are
                    well-versed in child development and have a deep
                    understanding of the needs and interests of young children.
                    They use this knowledge to design an engaging and
                    challenging curriculum that is tailored to meet the
                    individual needs of each child.
                  </p>
                  <p>
                    Our staff members are committed to providing a warm and
                    nurturing environment where children feel safe, happy, and
                    supported. They understand that early childhood education is
                    not just about teaching academic skills but also about
                    fostering social and emotional development. They work
                    closely with parents to ensure that each child receives the
                    care and attention they need to thrive.
                  </p>
                  <p>
                    At Kites Prepschool, we are proud of our staff and the work
                    they do to provide an exceptional education to our students.
                    We believe that our child-centric curriculum and abstract
                    approach to education set us apart from other preschools,
                    and we are committed to continuing to provide high-quality
                    education to our students for years to come.
                  </p>
                </div>
                {/* <div className="row">
                  {teamBlog.map((data, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                      <div className="dlab-box box-frame1 team-box m-b40">
                        <div className="dlab-thum">
                          <Link to={"/teachers-details"}></Link>
                          <div className="overlay-bx">
                            <h5 className="team-title">
                              <Link to={"/teachers-details"}>Jone Doe</Link>
                            </h5>
                            <span className="team-info">Teachers</span>
                            <ul className="list-inline">
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-facebook"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-google-plus"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-linkedin"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-instagram"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-twitter"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Teachers;
