import React, { Component } from "react";
import Slider from "react-slick";
import grid1 from "./../../images/testimonials/pic3.jpg";

const latestBlog = [
  {
    image: grid1,
    name: "Sunita Kalyani",
    desc: "Best school one can prefer for their kids. The environment that they create makes the kids comfortable and create interest to go to school even on Sundays 😁 had happened with my son. Teachers are very supportive, the method of teaching is perfect. My kid used to be a shy kid but now he  has a great stage courage. From principal to staff all are very responsible towards kids . Thank you for  setting right base.",
  },
  {
    image: grid1,
    name: "Madhurima Marisetty",
    desc: "Kites school has a very good curriculum and the method of teaching is amazing. The teachers are very friendly with the kids. With two years of online classes I was worried how my son would get along but Kites school has made everything easy. Not only from the curriculum perspective it also helped in his personality development. Now he is a very confident child who is able to manage everything himself. This especially was very much required with him moving to 1st standard this year.",
  },
  {
    image: grid1,
    name: "Sai Sairam",
    desc: "I am very fortunate to have found Kites School in Bangalore for my kids, at Kites all the teachers and staff are very dedicated towards their work, Kites School has created an environment where children grow, develop and learn with joy and confidence.",
  },
  {
    image: grid1,
    name: "Narmada Dhani Reddy",
    desc: "Very good prep school in BEML layout. The Acemadic concepts are too good as they teach through activities and concepts taught will be used in grade 1 also. I recommended this school as it is cleanly and good care takers and teachers are very very good in handling and taking care of kids..",
  },
  {
    image: grid1,
    name: "Kannimannu",
    desc: "Best preschool in Bangalore with excellent management. Best of teachers and care takers. The school area is super hygienic,I loved the way of teaching.",
  },
  {
    image: grid1,
    name: "Soujanya Reddy",
    desc: "With Kites school guidance, our son Parthiv has developed into a confident and capable child. Thank you for being such an important part in our child’s Development. Our son used to come home every day telling us stories about how fun it is to be in school. Your play-based learning approach is amazing! The past year has been difficult for our family, and you have been a stable and supportive part of our son's life through this time. Thank you all teachers of Kites school from the bottom of our hearts.",
  },
  {
    image: grid1,
    name: "Mohan Balaji P",
    desc: "Great school to start your kids early school education!! It was really a very good experience for my daughter as well as ourselves as a parents. My daughter went to this school from Pre Nursery till UKG. She never told to us that she don't want to go school even for a single day. All the teachers and non teaching staffs are very much caring..Geetha Mam is very hardworking and got a very good team. Their curriculum kreedo is very good..and have enough books and materials to teach your kids for a needed early stage education.",
  },
  {
    image: grid1,
    name: "Gautami S",
    desc: "This school was one of our best decisions  we made for our daughter. Staff in the school whether it be a teaching, management or maintenance everything takes top notch compared to other pre schools in the locality. We have taken admission when our kid was 2.5years and I must say she got best out of everything. Early education to the kids play vital role and teaching faculty here helps every kid to learn effortlessly. Definitely this kind of approach is possible only when faculty themselves have put up so much effort to make it seemingly easy for kids.",
  },
];
class TestiMonialSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    var settings = {
      arrows: false,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="sprite-nav">
          <Slider
            className="client-carousel owl-carousel owl-theme  "
            ref={(c) => (this.slider = c)}
            {...settings}
          >
            {latestBlog.map((item, index) => (
              <div className="item" key={index}>
                <div className="client-box">
                  <div className="testimonial-detail clearfix">
                    <div className="testimonial-pic radius">
                      <img src={item.image} width="100" height="100" alt="" />
                    </div>
                    <h5 className="testimonial-name m-t0 m-b5">{item.name}</h5>
                    <span>Parent</span>
                  </div>
                  <div className="testimonial-text">
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="owl-nav" style={{ textAlign: "center" }}>
            <div className=" owl-prev " onClick={this.previous} />
            <div className="owl-next " onClick={this.next} />
          </div>
        </div>
      </>
    );
  }
}
export default TestiMonialSlider;
