import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Masonry from "react-masonry-component";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";

//images
import gallery1 from "./../../images/gallery/annualDay/img1.jpg";
import gallery2 from "./../../images/gallery/annualDay/img2.jpg";
import gallery3 from "./../../images/gallery/annualDay/img3.jpg";
import gallery4 from "./../../images/gallery/annualDay/img4.jpg";
import gallery5 from "./../../images/gallery/annualDay/img5.jpg";
import gallery6 from "./../../images/gallery/annualDay/img6.jpg";
import gallery7 from "./../../images/gallery/annualDay/img7.jpg";
import gallery8 from "./../../images/gallery/annualDay/img8.jpg";
import gallery9 from "./../../images/gallery/annualDay/img9.jpg";
import gallery10 from "./../../images/gallery/annualDay/img10.jpg";
import gallery11 from "./../../images/gallery/annualDay/img11.jpg";
import gallery12 from "./../../images/gallery/annualDay/img12.jpg";
import gallery13 from "./../../images/gallery/annualDay/img13.jpg";

const galleryBlog = [
  { images: gallery1 },
  { images: gallery2 },
  { images: gallery4 },
  { images: gallery5 },
  { images: gallery6 },
  { images: gallery7 },
  { images: gallery8 },
  { images: gallery9 },
  { images: gallery10 },
  { images: gallery3 },
  { images: gallery11 },
  { images: gallery12 },
  { images: gallery13 },
];
// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={(e) => {
        e.preventDefault();
        openLightbox(props.imageToOpen);
      }}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

class Gallery extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Annual Day" activeMenu="Annual Day" />
          <div className="content-block">
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    Annual Day
                  </h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {galleryBlog.map((item, index) => (
                            <li
                              className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn"
                              key={index}
                            >
                              <div className="dlab-box frame-box m-b30">
                                <div className="dlab-thum dlab-img-overlay1 ">
                                  <img src={item.images} alt="" />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <Iconimage imageToOpen={index} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default Gallery;
