import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import Masonry from "react-masonry-component";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import AccordionBlog from "../Element/AccordionBlog";
import TestiMonialSlider from "../Element/TestiMonialSlider";
import VideoPopup from "../Element/VideoPopup";
//Images
import bnr1 from "./../../images/line2.png";
import bnr2 from "./../../images/background/bg5.jpg";
import bgimg2 from "./../../images/background/bg1.jpg";
import bnr4 from "./../../images/line2.png";
import about from "./../../images/about/about-3.jpg";
import gallery1 from "./../../images/gallery/pic1.jpg";
import gallery2 from "./../../images/gallery/pic2.jpg";
import gallery3 from "./../../images/gallery/pic3.jpg";
import gallery4 from "./../../images/gallery/pic4.jpg";
import gallery5 from "./../../images/gallery/pic5.jpg";
import gallery6 from "./../../images/gallery/pic6.jpg";

// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link onClick={() => openLightbox(props.imageToOpen)} className="">
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};
const galleryBlog = [
  { image: gallery1 },
  { image: gallery2 },
  { image: gallery3 },
  { image: gallery4 },
  { image: gallery5 },
  { image: gallery6 },
];

function BackgroundBlog() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head text-center">
              <div className="video-play">{/* <VideoPopup /> */}</div>
              <h2>
                Let Your Kids Have an Amazing
                <br />
                Time at Kites Prep School
              </h2>
              {/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function EducationBanner() {
  return (
    <>
      <div
        className="section-full bg-white content-inner-2 about-content bg-img-fix"
        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
      >
        <div className="about-overlay-box"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="section-head">
                <h2 className="head-title text-yellow">
                  Education from birth
                  <br />
                  begins with us
                </h2>
                <p className="text-white">
                  Our Kites Prep School, together with one of the oldest private
                  schools, created a joint preschool preparation project. The
                  goal of the project is the harmonious development,
                  socializationfor admission to the best educational
                  institutions of our city.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
          </div>
        </div>
      </div>
    </>
  );
}

class AboutUs1 extends Component {
  componentDidMount() {
    var splitBox = document.querySelectorAll(".split-box");

    var fSB = [].slice.call(splitBox);

    fSB.forEach((el) => el.classList.add("split-active"));
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="About Us " activeMenu="About Us " />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner-2 about-area"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <h2
                        className="head-title text-secondry"
                        style={{ color: "#a53692" }}
                      >
                        About Founder
                      </h2>
                      <p>
                        <strong> Mrs. Geetha Ramesh </strong>is a passionate and
                        dedicated Director at Kites Prep School, demonstrating a
                        profound commitment to nurturing young minds. Geetha's
                        unique perspective on early childhood education brings a
                        fresh and innovative approach to the field. <br /> With
                        an MSc in Biotechnology from Osmania University,
                        Hyderabad, Mrs. Geetha Ramesh's academic background is
                        underpinned by a diverse range of educational
                        certificates. Her continuous pursuit of knowledge is
                        evident through her participation in various
                        education-related workshops and certificate courses:{" "}
                      </p>
                      <p>
                        <strong>*</strong> Certificate in Daycare Management
                        from Podar Institute.
                        <br /> <strong>*</strong> Certificate course on
                        Friedrich Froebel's Kindergarten Approach from Podar
                        Institute. <br />
                        <strong>*</strong> Certificate course in Understanding
                        Children's Brain Development in the Early Years. <br />
                        <strong>*</strong> Certificate course in Priming the
                        Brain for Learning in the Early Years. <br />{" "}
                        <strong>*</strong> Teacher Enrichment and Education
                        course in early childhood care and education from ECA.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                    <div className="split-box">
                      <div className="about-media class-item">
                        <img src={about} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <p>
                        Geetha firmly believes that each child is a world of
                        their own, brimming with untapped potential. Her
                        unwavering commitment to this principle serves as the
                        cornerstone for an exceptional learning journey at Kites
                        Prep School. Recognizing the diversity among students in
                        terms of learning styles and paces, the school follows
                        Geetha's philosophy of tailoring education to individual
                        needs. This personalized approach ensures that every
                        student receives the necessary attention and guidance to
                        thrive.
                      </p>
                      <p>
                        One of Geetha's fundamental principles revolves around
                        embracing concrete-to-abstract learning. She understands
                        that young minds grasp abstract concepts best when
                        they're rooted in tangible experiences. This approach
                        not only makes learning engaging but also lays a solid
                        foundation for a profound and enduring understanding of
                        complex ideas.
                      </p>
                      <p>
                        Under Geetha's visionary leadership, Kites Prep School
                        goes beyond mere education; it fosters flourishing
                        students. You're invited to embark on an exciting
                        journey of discovery, growth, and achievement by
                        exploring our programs. Witness firsthand the impact of
                        Geetha's passion and expertise on early childhood
                        education. This dedication further underlines her
                        mission to provide a distinct and effective approach to
                        early childhood education.
                      </p>

                      <p>
                        At Kites Prepschool, we believe that every child is
                        unique and has their own learning style and pace. That's
                        why we have a child-centric curriculum that focuses on
                        the individual needs of each child. Our curriculum is
                        designed to encourage children to explore, learn and
                        discover at their own pace, in a safe and nurturing
                        environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <BackgroundBlog />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
              <div className="section-head">
                <h3 class="post-title m-t0">
                  Our child-centric curriculum is based on the following
                  principles
                </h3>

                <li>
                  <strong>Play-based learning:</strong> We believe that children
                  learn best through play. That's why we have designed our
                  curriculum to include a lot of hands-on, experiential learning
                  activities that are fun and engaging.
                </li>

                <li>
                  <strong>Inquiry-based learning: </strong>We encourage children
                  to ask questions, explore and discover on their own. Our
                  curriculum is designed to spark curiosity and encourage
                  children to learn by asking questions.
                </li>

                <li>
                  <strong>Individualized learning: </strong>Every child is
                  unique, and so are their learning needs. We recognize this and
                  provide individualized learning plans for each child based on
                  their interests, strengths and areas for development.
                </li>

                <li>
                  <strong>Collaborative learning:</strong> We believe that
                  children learn best when they work together. Our curriculum
                  includes a lot of group activities that encourage children to
                  work together, share ideas and learn from each other.
                </li>
                <p></p>
                <h3 class="post-title m-t0">
                  Concrete to Abstract Approach at Kites Prepschool
                </h3>
                <blockquote>
                  At Kites Prepschool, we follow a concrete to abstract approach
                  to learning. This approach is based on the idea that children
                  learn best when they are first introduced to concrete,
                  real-life examples before moving on to abstract concepts.
                </blockquote>
                <h3 class="post-title m-t0">
                  Our concrete to abstract approach is based on the following
                  principles:
                </h3>
                <li>
                  <strong>Hands-on learning:</strong> We believe that children
                  learn best when they can touch, feel and explore real-life
                  objects. That's why we provide a lot of hands-on learning
                  opportunities for our students.
                </li>
                <li>
                  <strong>Real-life examples:</strong> We use real-life examples
                  to introduce new concepts to our students. For example, we
                  might use blocks to teach math concepts like addition and
                  subtraction.
                </li>
                <li>
                  <strong>Visual aids:</strong> We use a lot of visual aids like
                  pictures, charts and graphs to help children understand
                  abstract concepts.
                </li>
                <li>
                  <strong>Gradual progression:</strong> We introduce new
                  concepts gradually, starting with simple, concrete examples
                  and gradually moving on to more abstract concepts.
                </li>
                <p>
                  By following a concrete to abstract approach, we ensure that
                  our students have a strong foundation in the basics before
                  moving on to more complex concepts. This approach helps our
                  students develop critical thinking skills, problem-solving
                  skills and a love for learning.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;
