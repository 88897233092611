import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import BannerSlider from "../Element/BannerSlider";
import FromSlider from "../Element/FromSlider";
import TestiMonialSlider from "../Element/TestiMonialSlider";
import TeacherSlider from "../Element/TeacherSlider";
import GallerySlider from "../Element/GallerySlider";

import icon1 from "./../../images/icon/icon1.jpg";
import icon2 from "./../../images/icon/icon2.jpg";
import icon3 from "./../../images/icon/icon3.jpg";
import icon4 from "./../../images/icon/icon4.jpg";
import bgimg1 from "./../../images/line.png";
import bnr3 from "./../../images/background/bg6.jpg";
import bgimg3 from "./../../images/line2.png";

const iconBlog = [
  { image: icon1, title1: "To Think Creatively", title2: "and Create" },
  {
    image: icon2,
    title1: "To Feel Fine and to",
    title2: "Understand Emotions",
  },
  { image: icon3, title1: "To be Independent", title2: "and Active" },
  { image: icon4, title1: "To Apply", title2: "Knowledge in Life" },
];
const iconBlog2 = [
  { icon: <i className="flaticon-rattle text-blue" />, title: "Infants" },
  { icon: <i className="flaticon-bricks text-green" />, title: "I myself" },
  { icon: <i className="flaticon-puzzle text-orange" />, title: "Goodie" },
];

class Index1 extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/* Slider Banner */}
          <BannerSlider />
          {/* Slider Banner */}
          <div className="content-block">
            {/*  About Us */}
            <div className="section-full bg-white content-inner-1 text-center">
              <div className="container">
                <div className="section-head">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    Welcome to Kites Prep School
                  </h2>
                  <p>Fill your child's childhood with the joy of learning!</p>
                </div>
                <div className="row">
                  {iconBlog.map((data, index) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-12"
                      key={index}
                    >
                      <div className="icon-bx-wraper sr-iconbox m-b20">
                        <div className="icon-lg m-b20">
                          <Link to={"#"} className="icon-cell">
                            <img src={data.image} alt="" />
                          </Link>
                        </div>
                        <div className="icon-content">
                          <h6 className="dlab-tilte">
                            {data.title1}
                            <br />
                            {data.title2}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/*  About Us End*/}
            <div
              className="section-full bg-white content-inner-2 about-box"
              style={{
                backgroundImage: "url(" + bgimg1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="section-head">
                      <h2
                        className="head-title text-secondry text-center"
                        style={{ color: "#a53692" }}
                      >
                        Our Vision
                      </h2>
                      <p>
                        Our Vision is to be the leading preschool in our
                        community, offering exceptional education and
                        development programs that inspire a lifelong love of
                        learning. We aim to be recognized for our innovative and
                        effective teaching methods, our supportive and inclusive
                        community, and our commitment to the growth and
                        well-being of every child in our care.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="section-head">
                      <h2
                        className="head-title text-secondry text-center"
                        style={{ color: "#a53692" }}
                      >
                        Our Mission
                      </h2>
                      <p>
                        Our Mission is to provide a safe and stimulating
                        environment where children can develop their social,
                        emotional, cognitive, and physical skills through play
                        and exploration. We strive to create an atmosphere that
                        fosters creativity, curiosity, and a passion for
                        learning. We believe in developing the whole child by
                        providing opportunities for hands-on learning, artistic
                        expression, physical activity, and community engagement.
                      </p>
                      {/* <Link to={"/contactus"} className="btn btn-md kids-btn radius-xl">Learn more</Link> */}
                    </div>
                  </div>

                  {/* <div className="col-lg-7 col-md-12 col-sm-12 col-12">
										<div className="section-head">
											<h2 className="head-title text-secondry">Do you dream that<br/>your child will become<br/>intelligent?</h2>
											<p>
												The concept of school and pre-school education consists of 3 programs of development and training in our academy, developed in collaboration with the institute of the children's university, which will help your children to learn subjects in the best possible way.
												</p>
											<Link to={"./faqs"} className="btn btn-md kids-btn radius-xl">Learn more</Link>
										</div>
									</div> */}
                  {/* <div className="col-lg-5 col-md-12 col-sm-12 col-12">
										{iconBlog2.map((item, index)=>(
											<div className="icon-bx-wraper left" key={index}>
												<div className="icon-lg m-b20"> <span className="icon-cell">{item.icon}</span> </div>
												<div className="icon-content">
													<h2 className="dlab-tilte">{item.title}</h2>
													<p>Lectus placerat a ultricies a,interdum donec eget metus auguen u Fusce mollis imperdiet interdum donec eget metus.</p>
												</div>
											</div>
										))}
									</div> */}
                </div>
              </div>
            </div>

            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr3 + ")" }}
            >
              <BackgroundBlog2 />
            </div>
            {/* <div className="section-full bg-white content-inner-1">
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    Gallery
                  </h2>
                  </div>
                <GallerySlider />
              </div>
            </div> */}
            {/* <div
              className="section-full bg-white content-inner-1"
              style={{
                backgroundImage: "url(" + bgimg3 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    About the Teachers
                  </h2>
                  </div>
                <TeacherSlider />
              </div>
            </div> */}
            <div className="section-full bg-white content-inner-1">
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#a53692" }}
                  >
                    Testimonials
                  </h2>
                  {/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
                </div>
                <TestiMonialSlider />
              </div>
            </div>
            {/* <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">From the Blog</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<FromSlider />
							</div>
						</div> */}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

function BackgroundBlog2() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head ">
              <h4 className="text-white">Join Our New Session</h4>
              <h2>
                Call To Enrol Your Child <br />
                <span className="text-yellow">080-49577622</span>
              </h2>
              {/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { BackgroundBlog2 };
export default Index1;
